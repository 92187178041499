import { ReactNode } from "react";
import { Kontakt } from "@/types/kontakt";
import { getShortenedFirstName } from "@/util/nameUtil";

type SkiftBrugernavnBaseType = {
  onClick: () => void,
  skiftBrugernavnDisabled: boolean,
  children?: ReactNode,
  kontakt: Kontakt;
}

export default function SkiftBrugernavnBase({ onClick, skiftBrugernavnDisabled, children, kontakt }: SkiftBrugernavnBaseType) {
  return <>
    <h2>{kontakt.fornavn}</h2>
    {children}
    {
        !skiftBrugernavnDisabled && <>
          <p>Du er i gang med at skifte brugernavn på <i>{kontakt.fornavn}</i>.
            <br />Det nuværende brugernavn
            er <span id="kontaktUsername"><strong>{kontakt.username}</strong></span>.
          </p>
          <p>Ved skift af brugernavn benyttes fornavnet efterfulgt af et tal.<br />
            <i>Eksempelvis bliver {kontakt.fornavn} til f.eks. {getShortenedFirstName(kontakt.fornavn)}.</i>
          </p>


          <button className={"button button-primary"} onClick={onClick}>Skift brugernavn</button>
        </>
    }
  </>
}
